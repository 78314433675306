import Appsignal from "@appsignal/javascript"
import { plugin as pathDecoratorPlugin } from "@appsignal/plugin-path-decorator"
import { plugin as windowEventsPlugin } from "@appsignal/plugin-window-events"

const body = document.querySelector("body")

const appsignal = new Appsignal({
  key: body.dataset.appsignalFrontendKey,
  uri: "https://error-tracker.staging.lol/collect",
  revision: body.dataset.appRevision,
  ignoreErrors: [/ResizeObserver/, /No event details available/, /Identifier 'originalPrompt' has already been declared/, /The element has no supported sources/],
})

const plugins = [windowEventsPlugin, pathDecoratorPlugin]
plugins.forEach((plugin) => appsignal.use(plugin()))

window.appsignal = appsignal
